$primary-main: #800020;
$primary-darker: #5a0016;
$primary-darkest: #34000D;
$primary-lightest: #ebc5cf;

$secondary-main : #f5dd56;

$grey: #9c9c9c;

$hoverWhiteButton: #a5a5a55e;

$text-grey: #515151;

$accountPagesBorders: #ccc;
