@use 'src/theme/colors' as craenen-colors;
@use 'src/theme/material-theme' as craenen-mat-theme;
@use 'src/theme/measurements' as craenen-measurements;
@import '@angular/cdk/overlay-prebuilt.css';
@import 'ckeditor5/ckeditor5.css';

:root {
    --mdc-checkbox-selected-checkmark-color: #fff;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tooltip.tooltip {
  color: #ffffff !important;
  background-color: craenen-colors.$primary-darker;
  font-size: 12px;
  font-weight: 600 !important;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.hover-class {
  cursor: pointer;
    color: craenen-colors.$primary-main !important;
    font-weight: 550;
}


.pageWrapper {
  position: relative;
  min-height: 100vh;

  font: Tahoma, Verdana, sans-serif;

  .contentWrapper {
      padding-bottom: 710px;

      #header {
          height: craenen-measurements.$header-height;
          width: 100%;
      }
  }

  .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: craenen-colors.$primary-darker;
  }

  form {

    input {
      caret-color: craenen-colors.$primary-main !important;
    }

    ::ng-deep .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label {
      color: craenen-colors.$primary-main !important;
    }

    ::ng-deep .mat-mdc-form-field-appearance-outline.mat-mdc-focused .mat-mdc-form-field-outline-thick {
      color: craenen-colors.$primary-main !important;
    }

    ::ng-deep .mat-mdc-form-field-flex:hover .mat-mdc-form-field-outline-thick {
      color: craenen-colors.$primary-main !important;
    }

    .form-row {
      display: flex;
      justify-content: flex-start;
      flex: 1;
      margin-bottom: 10px;
    }

    .double-form-row, .single-half-form-row {
      mat-form-field {
        margin-right: 4%;
        width: 48%;
      }

      mat-form-field:last-child {
        margin-right: 0;
      }
    }

    .double-mobile-form-row {
      display: flex;
      flex-direction: column;
    }

    .single-full-form-row {
      width: 100%;

      mat-form-field {
        width: 100%;
      }
    }
  }
}

.tablet-layout, .desktop-layout {
  .contentWrapper {
    width: 95%;
    margin: auto;
    padding-bottom: 303px;
  }
}

.desktop-layout {
  .contentWrapper {
    max-width: craenen-measurements.$page-width;
    margin: auto;
    padding-bottom: craenen-measurements.$footer-height;
  }

  .footer {
    height: craenen-measurements.$footer-height;
  }
}

.snackBarStyling {
  --mdc-snackbar-container-color: red;
  --mdc-snackbar-supporting-text-color: white;
  margin: 50px auto !important;

  .mdc-snackbar__surface {
    min-width: unset !important;
    padding: 0 !important;

  }

  &.mat-mdc-snack-bar-container .mdc-snackbar__label {
    font-weight: 550 !important;
  }
}

// .mat-mdc-checkbox.text-wrap {
//   label.mat-checkbox-layout {
//     white-space: normal;
//   }
// }

// .mat-mdc-checkbox {
//   .mat-mdc-formfield {
//     label {
//       background-color: hotpink;
//     }
//   }
// }

.mat-radio-label {
  white-space: normal !important;
}

.mdc-button {
  letter-spacing: normal;
}

figure {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    unicode-bidi: isolate;
}
